/* AboutUs.css */

/* Existing styles for text and button */
.text p {
  font-weight: 700;
  font-size: 15px;
  display: inline;
}

.text .read-more {
  font-size: 14px;
  cursor: pointer;
}

.read-more {
  display: none;
}

.aboutrow {
  box-shadow: 0px 0px 1px 0.13px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  padding: 15px;
}

.allCard {
  margin-top: 20px;
}

.aboutImg {
  border-radius: 5px 5px 5px 5px;
  height: 100%;
  width: 100%;
  object-fit: cover;
  /* max-width: 14rem; */
  max-height: 10rem;
}

.mob-about {
  display: none;
}

@media only screen and (max-width: 2560px) {
  .textContent {
    font-size: 18px;
    font-weight: 500;
    margin-top: 3px;
    line-height: 22px;
  }
  .headText {
    font-size: 24px;
    font-weight: 600;
  }
}

@media only screen and (max-width: 1024px) {
  .textContent {
    font-size: 14px;
    font-weight: 400;
  }
  .headText {
    font-size: 16px;
    font-weight: 600;
  }
}

@media only screen and (max-width: 768px) {
  .textContent {
    font-size: 9px;
    font-weight: 400;
  }
  .headText {
    font-size: 13px;
    font-weight: 600;
  }
  .read-more-btn{
    font-size: 9px !important;
  }
}

@media only screen and (max-width: 480px) {
  .allCard {
    margin-top: 0px;
  }

  .a-abot {
    font-size: 12px;
  }

  .textMobContent {
    text-align: justify;
    font-size: 12px;
    line-height: 13px;
    padding-top: 7px;
  }

  .mob-about {
    padding-left: 10px;
    display: inline-block;
  }

  .Web-about {
    display: none;
  }

  .aboutImg {
    height: 66px;
    object-fit: cover;
  }

  .text span {
    font-size: 13px;
    text-align: justify;
    font-weight: 500;
  }

  .textContent {
    display: none;
  }

  .text .read-more {
    font-size: 10px;
  }

  .read-more {
    display: inline;
    display: flex;
    justify-content: end;
  }

  .mainHeadAbout {
    display: none;
  }

  @media only screen and (max-width: 320px) {
    .text span {
      font-size: 10px;
      font-weight: 700;
    }
    .textContent {
      display: none;
    }
    .text .read-more {
      font-size: 10px;
    }
    .read-more {
      display: inline;
      display: flex;
      justify-content: end;
    }
  }
}

.read-more-btn {
  display: inline;
  background: none;         
  border: none;             
  color:  #247496;              
  font-size: 18px;          
  cursor: pointer;         
  padding: 0;          
  text-decoration: underline;
}

.read-more-btn:hover {
  color: #247496;         
  text-decoration: none;    
}
.custom-text-about {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4; 
  overflow: hidden;
  text-overflow: ellipsis;
  transition: max-height 0.3s ease-in-out;
}
.custom-text-about.expanded {
  -webkit-line-clamp: unset; 
  overflow: visible;
}
