.btn-profile{
    text-align: center;
}
.edit-psd-btn{
    background-color: #247496;
    padding: 4px 20px !important;
    font-size: 20px !important;
    border:none
}
.edit-psd-btn:hover,.edit-psd-btn:active{
    background-color: #247496  !important;
    padding: 4px 20px !important;
    border:none
}  
h5::after{
    display: none !important;
}
@media (max-width: 786px){
    .Form-field-parent button {
        background-color: #247496;
        color: white;
        padding: 7px 55px ;
        margin-top: 3px;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        font-size: 14px !important;
        font-weight: 500;
        margin-left: 6px;
    }
}
.btn-submit:hover, .btn-submit:active{
    background-color: #247496 !important;
}