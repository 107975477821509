.fist-card-home {
  position: relative;
}
.card-image-home{

   position: relative;
}
.card_content {
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
}
.main_content_for_absoulte {
  width: 100%;
  border-radius: 0px 0px 10px 10px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 53px 0px 0px 10px;
  background: linear-gradient(0deg, #000000 -24.05%, rgba(0, 0, 0, 0) 94.59%);
}
.home-card-img {
  height: 19.3rem;
  border-radius: 11px;
  width: 100%;
}
.card-img-top {
  /* max-width: 100%; */
  /* height: 215px; */
  height: 195px;
  object-fit: cover;
  /* max-height: 200px; */
  width: 100%;
}
.for_img_text {
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  bottom: 95px;
  border-bottom: 2px solid white;
  width: 90%;
}
.for_img_text2 {
  position: absolute;
  bottom: 0px;
}


.top-image-text-news{
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
}


.home-card-img3 {
  height: 13rem;
  border-radius: 11px;
  filter: brightness(0.5);
}
.for_hrline {
  border: 1px solid white;
  width: 91% !important;
}
.lorem-text {
  padding: 0px 85px 0px 0px;
}
.for-info-section {
  position: relative;
}

.card-text4
 {
  display: flex;
      gap: 10px;
      align-items: center;
      margin-bottom: 30px;
      position: absolute;
      left: 11px;
      top: 260px;
  }
  

.card-cont1 {
  position: relative;
}
.card-text-style {
  position: absolute;
  bottom: 92px;
  display: flex;
  gap: 10px;
}

.card-text2 {
  display: flex;
  gap: 10px;
}
.card-content {
  position: relative;
}
.card-para-content {
  position: absolute;
  bottom: -12px;
  padding: 0px 23px 1px 10px;
}
.card-para-content2 {
  position: absolute;
  bottom: -5px;
  padding: 0px 23px 1px 10px;
}

.card-img-para p {
  color: #ffffff;
  font-size: 18px;
  top: 205px;
  font-weight: 500;
  line-height: 21.68px;

  display: -webkit-box;
  -webkit-line-clamp: 2; /* Restrict to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.newschanel{
  font-size: 16px;
  font-weight: 500;
}

.cont-on-img .line {
  height: 1px;
  background-color: #6a1010;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}

.img-second-card img {
  width: 100%;
}

a:hover {
  /* color: #0056b3; */
  text-decoration: none;
}
.home-card-seco {
  margin-bottom: 30px;
}

.img-second-card .cont-on-img .card-text-style {
  position: absolute;
  bottom: 105px;
  color: #fff;
  display: flex;
  gap: 10px;
}

.img-fourth-card img {
  width: 100%;
}

.img-fourth-card {
  margin-bottom: 11px;
}

.img-fourth-card .cont-on-img .card-text-style {
  position: absolute;
  bottom: -18px;
  color: #fff;
  display: flex;
  gap: 10px;
}

.img-fourth-card .cont-on-img {
  position: absolute;
  bottom: 46px;
  padding: 0px 52px 0px 10px;
}



.cont-on-img .card-text-style img {
  width: 55px;
  height: 28px;
}

.cont-on-img .card-text4 .card-texts4 img {
  width: 44px;
  height: 20px;
}

.cont-on-img .card-text4 .card-texts4 span {
  color: white;
}
.medi-btn button {
  width: 100%;
  border: none;
  background-color: #247496;
  color: #ffff;
  padding: 12px;
  border-radius: 0px 0px 6px 6px;
}

.medi-card-img img {
  width: 100%;
}

.medi-para p {
  font-size: 18px;
  font-weight: 500;
  color: #000000;
  line-height: 20.68px;
  margin-bottom: 0;
  padding: 10px 14px 24px 10px;
}

.medi-data-card {
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 0px 10px 0 rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  margin-bottom: 20px;
}
.font-color-text-white{

   color: white;
}

.company-img img {
  width: 100%;
}

.company-img {
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 0px 10px 0 rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  margin-bottom: 20px;
}
/* .swiper-m-right{
  margin-right: 20px !important;
} */



.row-span-2 {
  height: 100% !important;
}
/* .for-Medicine-img-1{
  object-fit: cover;
  height: 108% !important;
} */

.for_img-text_for_mobile{
  font-weight: 400;
  font-size: 16px;
}



h5 {
  position: relative;
  display: inline-block;
  margin-bottom: 20px; /* Adjust spacing below the h5 if needed */
}

h5::after {
  content: "";
  display: block;
  width: 50%; 
  height: 2px; 
  background-color: black; 
  position: absolute;
  left: 0;
  bottom: -5px; 
}
.All-col-home{
  margin-top: 30px;
}


@media (max-width: 1399px) and (min-width: 1025px) {
  .Business-images {
    height: 188px !important;
    width: 100%;
}

}



@media (max-width: 2560px){





  .Business-images {
    height: 267px !important;
    width: 100%;
}

  .rowLink1 li{
    float: left;
    padding-right: 30px;
  }
  .rowLink2 li{
    float: left;
    padding-right: 30px;
  }
  .lorem-text {
    padding: 0px 85px 0px 0px;
}
}
@media (max-width: 1440px){
  .rowLink1 li{
    float: left;
    padding-right: 30px;
  }

  .Business-images{
    height: 270px !important;
    width: 100%;
  }

  .rowLink2 li{
    float: left;
    padding-right: 30px;
  }
  .lorem-text {
    padding: 0px 85px 0px 0px;
}
}
@media (max-width: 1024px){

  .Business-images{
    height: 188px !important;
    width: 100%;
  }


  .card-texts4 {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 30px;
    position: absolute;
    left: 0px;
    top: 100% !important;
    width: 44px;
    height: 20px;
  }

  .card-img-para p {
    color: #ffffff;
    font-size: 18px;
    top: 215px;
    font-weight: 500;
    line-height: 21.68px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
  

  .for_img_text {
    bottom: 105px;
}

  .top-image-text{
    font-size: 14px !important;
    line-height: 18px !important;
  }
  .home-card-img {
    height: 18.45rem;
}
  .leftImg {
    height: 20px ;
    width: 40px;
    object-fit: cover;
    /* display: block; */

}
  /* .newschanel {
    font-size: 16px;
} */
  .card_content {
    font-size: 14px;
    line-height: 18px;
}
  
  .rowLink1 li{
    float: left;
    padding-right: 30px;
  }
  .rowLink2 li{
    float: left;
    padding-right: 30px;
  }
  .lorem-text {
    padding: 0px 85px 0px 0px;
}
.card-text4 {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 30px;
  position: absolute;
  left: 11px;
  top: 270px !important;
}


}
@media (max-width: 380px) {
  .custom-mobile-view-news {
       font-size: 14px;
      color: #383838 !important;
  }
}

@media (max-width: 480px) {


  .card-texts4 {
    display: flex
;
    gap: 10px;
    align-items: center;
    margin-bottom: 30px;
    position: absolute;
    left: 0px;
    top: 100%;
    width: 44px;
    height: 20px;
}



  .for_img-text_for_mobile{
    font-weight: 400;
    font-size: 12px !important;
  }

  .home-card-img {
    height: 16rem !important;
}

.for_img_text {
  bottom: 88px !important;
}

  .card-img-top {
    height: 85px !important;
}

  .top-image-text{
    font-size: 14px !important;
    line-height: 18px !important;
  }


  .card_content {
    font-size: 14px;
    font-weight: 500;
}
.pdRow{
  margin-top: 10px;
}
.card-img-top {
  /* max-width: 100%; */
  height: 90px;
  /* max-height: 200px; */
  width: 100PX;
}
  .card-img-para p {
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.09px;
  }
  .home-card-img {
    height: auto;
    border-radius: 0px !important;
    /* filter: brightness(0.7); */
  }
  .custom-cards-gap{

     margin-top: 4% !important;
  }
  .custom-margin-home{
     margin-top: 0% !important;
     margin-bottom: 0 !important;
  }

  .card-img-para p span {
    display: none;
  }

  .cont-on-img .card-text-style span {
    color: #ffff;
    font-size: 15px;
    font-weight: 500;
  }

  .cont-on-img .card-text-style {
    position: relative;
    bottom: 52px;
    color: #fff;
    display: flex;
    gap: 10px;
  }

  .fist-card-home {
    margin-bottom: 10px;
  }

  .img-second-card {
    margin-bottom: 10px;
  }

  .card-text-style {
    position: static;
    bottom: 92px;
    display: flex;
    gap: 10px;
    padding: 13px 0px 0px 12px;
  }
  .news.card-para p {
    padding: 6px 14px 1px 10px;
    font-weight: 500;
  }
  .font-color-text {
    color: black !important;
  }
  .lorem-text {
    padding: 0px 43px 0px 0px !important;
    font-size: 14px !important;
    text-align: justify;
}
.for_img_text {
  width: 90%;
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  bottom: 88px;
  border-bottom: 2px solid white;
}
  
  .card-para-content2 {
    position: absolute;
    bottom: -10px;
    padding: 0px 23px 1px 10px;
  }
  .for-mobile-view {
    font-size: 13px !important;
  }
  .custom-class-for-medicine{
    margin-top: 5%;
  }
  /* .custom-class-for-medicine-section{
    margin-top: 5%;
  } */
  .company-img {
    margin-bottom: 0px;
  }
  .medi-data-card {
    margin-bottom: 0px;
 }
 .company-logo{
       
   margin-top: 4%;
 }
}


@media (max-width: 380px) {

  .top-image-text {
    font-size: 13px !important;

}
  .card-img-para p {
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.09px;
  }
  .home-card-img {
    height: auto;
    border-radius: 0px !important;
  }

  .card-img-para p span {
    display: none;
  }

  .cont-on-img .card-text-style span {
    color: #ffff;
    font-size: 15px;
    font-weight: 500;
  }

  .cont-on-img .card-text-style {
    position: relative;
    bottom: 52px;
    color: #fff;
    display: flex;
    gap: 10px;
  }

  .fist-card-home {
    margin-bottom: 10px;
  }

  .img-second-card {
    margin-bottom: 10px;
  }

  .card-text-style {
    position: static;
    bottom: 92px;
    display: flex;
    gap: 10px;
    padding: 13px 0px 0px 12px;
  }
 
  .news.card-para p {
    padding: 6px 14px 1px 10px;
    font-weight: 500;
    font-size: 13px !important;
  }
  .font-color-text {
    color: black !important;
  }
  .lorem-text {
    padding: 0px 20px 0px 0px !important;
    font-size: 14px !important;
    text-align: justify;
  }
  .for_img_text {
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute;
    bottom: 118px;
  }
  .for-mobile-view {
    font-size: 12px !important;
  }
 
  .card-para-content2 {
    position: absolute;
    bottom: -10px;
    padding: 0px 23px 1px 10px;
  }
  .custom-class-for-medicine{
    margin-top: 5%;
  }
  .custom-class-for-medicine-section{
    margin-top: 5%;
  }
  .company-img {
    margin-bottom: 0px;
  }
  .medi-data-card {
    margin-bottom: 0px;
 }
 .company-logo{
       
  margin-top: 4%;

}

}

@media (max-width: 320px) {
  .leftImg {
    height: 20px;
    object-fit: cover;

    width: 30px;
}
  .card-img-para p {
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.09px;
    
  }
  .top-image-text {
    font-size: 11px !important;
    line-height: 18px !important;
}
  .home-card-img {
    height: auto;
    border-radius: 0px !important;

  }

  .card-img-para p span {
    display: none;
  }

  .cont-on-img .card-text-style span {
    color: #ffff;
    font-size: 15px;
    font-weight: 500;
  }

  .cont-on-img .card-text-style {
    position: relative;
    bottom: 52px;
    color: #fff;
    display: flex;
    gap: 10px;
  }

  .fist-card-home {
    margin-bottom: 10px;
  }

  .img-second-card {
    margin-bottom: 10px;
  }

  .card-text-style {
    position: static;
    bottom: 92px;
    display: flex;
    gap: 10px;
    padding: 13px 0px 0px 12px;
  }
  .news.card-para p {
    padding: 6px 14px 1px 10px;
    font-weight: 500;
    font-size: 11px !important;
  }
  .font-color-text {
    color: black !important;
    font-size: 12px !important;
  }
  .lorem-text {
    padding: 0px 20px 0px 0px !important;
    font-size: 10px !important;
    text-align: justify;
  }
  .for_img_text {
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute;
    bottom: 79px;
  }
  .for-mobile-view {
    font-size: 13px !important;
  }
 
  .card-para-content2 {
    position: absolute;
    bottom: 0px;
    padding: 0px 0px 1px 10px;
  }
  .custom-class-for-medicine{
    margin-top: 5%;
  }
  .custom-class-for-medicine-section{
    margin-top: 5%;
  }
  .company-img {
    margin-bottom: 0px;
  }
  .medi-data-card {
    margin-bottom: 0px;
 }
 .company-logo{
  margin-top: 4%;
}
.for_img-text_for_mobile{
   font-size: 12px;
}


}

.for-Medicine-img-home{
  object-fit: cover;
  /* border-radius: 10px; */
  width: 100%;

}


.for-Medicine-img {
  object-fit: cover;
  height: 100%;
  border-radius: 10px;
  width: 100%;


} 

.for-Medicine-img-sec{
  height: 300px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.card-text5{
 
    margin-bottom: 10px;
}
/*

.Gapping{
  margin-top: 0% !important;
  margin-bottom: 0% !important;
} */

.card-gap{
  padding: 10px;
  /* margin-bottom: 20px; */
}

.swiper.swiper-initialized.swiper-horizontal.mySwiper.mt-3.for_mobile_viewswiper {
  padding: 0px 9px;
}

@media (max-width: 1400px) {
 
  .news-card-image {
    /* height: 15.3rem !important; */
    object-fit: cover;

    border-radius: 11px;
    width: 100%;
  }
}


@media (max-width: 576px) {

  .myheight img {
    /* height: 100px ; */
}
}



.news-card-image {
  object-fit: cover;

  height: 19.3rem;
  border-radius: 11px;
  width: 100%;
}




/* .srcurg{
 height: 30px;
 width: 20px;
} */






@media (min-width: 768px) and (max-width: 1400px) and (orientation: portrait) {
  .srcin {
    object-fit: cover;
      height: 20px !important;
      width: 30px;
      display: block;
  }
}

@media only screen and (max-width: 768px) and (min-width: 320px) {
  .srcin {
    object-fit: cover;
    height: 20px !important;
    width: 40px;
    /* display: block; */
}

}


.swiper-logo-img{
  position: absolute;
  top: -30px;

}







.custom-swiper {
  width: 100%;
}

/* .custom-swiper-slide {
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
} */

.custom-card-img {
  position: relative;
  height: 380px;
  width: 100%;
  overflow: hidden;
}

.custom-medicine-img {
  border-radius: 10px;
  transition: transform 0.3s ease;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* .custom-medicine-img:hover {
  transform: scale(1.05);
} */

.custom-overlay-content {
  width: 100%;
  border-radius: 0px 0px 10px 10px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 53px 0px 10px 10px;
  background: linear-gradient(0deg, #000000 -24.05%, rgba(0, 0, 0, 0) 94.59%);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align content to the start (left side) */
  justify-content: flex-start; /* Align content to the start (top of the padding area) */
}

.custom-logo-img {
  object-fit: cover;
  width: 50px;
  margin-bottom: 5px;
}

.custom-logo-title {
  font-size: 1.2rem;
  margin-bottom: 5px;
}

.custom-card-paragraph {
  font-size: 1rem;
  margin: 0;
  line-height: 1.2;
}

@media (max-width: 768px) {

  .Business-images {
    height: 134px !important;
    width: 100%;}

  .custom-swiper-slide {
    width: 90%;
    margin: 0 auto;
  }

  .card-text4 {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 30px;
    position: absolute;
    left: 11px;
    top: 270px !important;
}

  .card-img-para p {
    color: #ffffff;
    font-size: 18px;
    top: 215px;
    font-weight: 500;
    line-height: 21.68px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

    .text-light-Bussiness {
        position: absolute;
        left: 20px;
        top: 180px !important;
       
        -webkit-box-orient: vertical;
        display: -webkit-box;
        text-shadow: 4px 4px 4px black;
        color: #ffffff;
        font-size: 18px;
        font-weight: 500;
        line-height: 21.68px;
    }


  .custom-overlay-content {
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
  }

  .card-texts4 {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 30px;
    position: absolute;
    left: 0px;
    top: 100% !important;
    width: 44px;
    height: 20px;
  }



}

.img-absolutes-News {
  position: absolute;
  bottom: 0px; 
  /* font-size: 20px; */
  display: flex;
  align-items: center;
  gap: 10px;
}

@media (max-width: 576px) {
  .img-absolutes-News {
      position: absolute;
      left: 17px;
      display: flex;
      align-items: center;
      gap: 6px;
      bottom: 93px;
  }
  .card-text4 {
    display: flex
;
    gap: 10px;
    align-items: center;
    margin-bottom: 30px;
    position: absolute;
    left: 11px;
    top: 270px;
}
}


.card-texts4{
  display: flex;
      gap: 10px;
      align-items: center;
      margin-bottom: 30px;
      position: absolute;
      left: 11px;
      top: 100px;
      width: 44px;
      height: 20px;
  }
 
  
  .text-light-Bussiness {
    position: absolute;
    left: 20px;
    top: 540px !important;
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-shadow: 4px 4px 4px black; 
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.68px;
  }
  
  .spacing-cards{
    margin-top: -8px !important;
  }

  .text-four-cards{
    position: absolute;
    --bs-text-opacity: 1;
    color: white !important;
    font-size: 18px;
    top: 140px;
    width: 80%; 
    white-space: normal; 
    left: 8px;
    -webkit-line-clamp: 2; 
    -webkit-box-orient:block-axis; 
    display: -webkit-box; 
    overflow: hidden; 
    text-shadow: 2px 2px 4px black; 
  }



  @media screen and (max-width: 375px) {
    .card-text4 {
      display: flex
;
      gap: 10px;
      align-items: center;
      margin-bottom: 30px;
      position: absolute;
      left: 11px;
      top: 170px;
  }

  }
  .business-text {
    position: absolute;
    top: 10px;
    font-size: 18px;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limits to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}


@media screen and (max-width: 1024px) {

  .text-four-cards{
    position: absolute;
    --bs-text-opacity: 1;
    color: white !important;
    font-size: 18px;
    top: 77px;
    width: 80%; 
    white-space: normal; 
    left: 8px;
    -webkit-line-clamp: 2; 
    -webkit-box-orient:block-axis; 
    display: -webkit-box; 
    overflow: hidden; 
    text-shadow: 2px 2px 4px black; 
  }

.business-text {
  position: absolute;
  top: 10px;
  font-size: 18px;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limits to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}


.text-light-Bussiness {
  position: absolute;
  left: 20px;
  top: 265px;
  -webkit-line-clamp: 2; 
  -webkit-box-orient: vertical;
  display: -webkit-box;
  text-shadow: 4px 4px 4px black; 
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.68px;
}

}

@media (max-width: 425px) {

  .card-text4 {
    display: flex
;
    gap: 10px;
    align-items: center;
    margin-bottom: 30px;
    position: absolute;
    left: 11px;
    top: 270px !important;
}



  .text-light-Bussiness {
    position: absolute;
    left: 20px;
    top: 450px !important;
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-shadow: 4px 4px 4px black; 
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.68px;
  }

  
.card-img-para p {
  color: #ffffff;
  font-size: 18px;
  top: 215px;
  font-weight: 500;
  line-height: 21.68px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-texts4 {
  display: flex
;
  gap: 10px;
  align-items: center;
  margin-bottom: 30px;
  position: absolute;
  left: 0px;
  top: 100% !important;
  width: 44px;
  height: 20px;
}


}



@media (max-width: 375px) {

  .card-text4 {
    display: flex
;
    gap: 10px;
    align-items: center;
    margin-bottom: 30px;
    position: absolute;
    left: 11px;
    top: 270px !important;
}

  .text-light-Bussiness {
    position: absolute;
    left: 20px;
    top: 400px !important;
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-shadow: 4px 4px 4px black; 
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.68px;
  }

  .card-img-para p {
    color: #ffffff;
    font-size: 18px;
    top: 215px;
    font-weight: 500;
    line-height: 21.68px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-texts4 {
  display: flex
;
  gap: 10px;
  align-items: center;
  margin-bottom: 30px;
  position: absolute;
  left: 0px;
  top: 100% !important;
  width: 44px;
  height: 20px;
}


}




@media (max-width: 320px) {
  .text-light-Bussiness {
    position: absolute;
    left: 20px;
    top: 330px !important;
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-shadow: 4px 4px 4px black; 
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.68px;
  }

  .card-img-para p {
    color: #ffffff;
    font-size: 18px;
    top: 215px;
    font-weight: 500;
    line-height: 21.68px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-text4 {
  display: flex
;
  gap: 10px;
  align-items: center;
  margin-bottom: 30px;
  position: absolute;
  left: 11px;
  top: 270px !important;
}

.card-texts4 {
  display: flex
;
  gap: 10px;
  align-items: center;
  margin-bottom: 30px;
  position: absolute;
  left: 0px;
  top: 100% !important;
  width: 44px;
  height: 20px;
}
}

.card-texts4 {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 30px;
  position: absolute;
  left: 0px;
  top: 100%;
  width: 44px;
  height: 20px;
}



.full-buss-data{
  height: 100% !important;
}

.buss-sec-data{
  height: 50px;
}
.box-container{
  width: 200px;
  height: 300px;
  border-radius: 10px;
}
.box-image{
  width: 200px;
  height: 200px;
  border-radius: 10px 10px 0px 0px;
}
.box-title{
  width: 150px;
  height: 200px;
}
.card1{
  width: 262px;
  height: 278px;
  border-radius: 6px;
  box-shadow: 0px 0px 10px 1px rgba(194, 194, 194, 1);
  cursor: pointer;
  transition: 0.4s;
}
.card1 .card_image {
  width: 262px;
  height: 195px; 
  position: relative;
  border-radius: 6px;
}
.card1 .card_image .card_image_top {
  width: 262px;
  height: 195px; 
  border-radius: 6px 6px 0px 0px;
  object-fit: cover;
}
.card1 .card_title {
  padding: 12px;
  border-radius: 0px 0px 6px 6px;
  height: 85px;
}
.title-black {
  color: #000;
}
.for-custom-card-text{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
}
.mob-hide {
  display: block;

}

@media (max-width: 576px) {

  .mob-hide.empty {
    display: none;
  }

  .mob-hide:not(.empty) {
    display: block;
  }
}