.custom_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px 8px 0px;
}
.arrorstyl{
  height: 28px;
  width: 28px;
  color: #fff;
}

.address {
  font-size: 18px;
  font-weight: 600;
}
.datetime{
  font-size: 14px;
}

.phone {
  color: black;
  font-size: 18px;
  font-weight: 600;
  padding-right: 0px !important;
  padding-left: 10px !important;
}
.footRow{
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.footRow span{
  font-size: 20px;
  padding-left: 22px;
}
.bott-aboutus {
  width: 660px;
  margin-top: 20px;
}
.for-location-icon{

   color: #247496;
}

.footer-left
{
  margin-top: 6px;
  font-size: 18px;
  font-weight: 500;
  color: white;
}

.footer-left h6{
  color: white;
  padding-left: 10px;
}

.footer-right {
  /* display: flex; */
  /* align-items: center; */
  /* padding: 0; */
  margin-top: 8px;
}

/* .footer-right span {
      margin-right: 10px;
    } */

    .footer-top {
      background: #247496;
      /* width: 100%; */
      display: flex;
      height: 60px;
  }
/* .footer-right input {
    padding: 5px;
    border-radius: 4px;
    border: none;
    outline: none;
  } */

.footer-info {
  display: flex;
  flex-direction: column;
}

.footer-info div {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.icon {
  margin-right: 10px;
  color: #247496;
  margin-left: 16px;
}

.footer-links a {
  color: black;
  /* text-decoration: none;
      margin-left: 20px; */
}

.footerrd input{
  border-radius: 2px 0px 0px 2px !important;
}
.footerrd button{
  border-radius: 0px 2px 2px 0px !important;
}
input.form-control {
  padding-left: 5%;
}
.footer-logo {
  display: flex;
  align-items: center;
  padding: 25px;
  justify-content: center;
  /* height: 44px; */
  /* width: 201px; */
}

/* span.input-group-text {
    width: 12%;
  } */

/* .footer-top .input-group input.form-control {
    height: 31px;
   
  } */



.botm-links a.d-block {
  color: black;
  text-decoration: none;
}
.rowLink {
  display: flex;
  justify-content: center;
  font-weight: 600;
  align-items: center;
  font-size: 18px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.rowLink a{
  display: inline !important;

}
/* .input-group-text {
  
    display: flex;
  
    align-items: center;
    padding: .375rem 1.5rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #ffffff;
    text-align: center;
    white-space: nowrap;
    background-color: #247496;
    border: 1px solid #ffffff;
    border-radius: .25rem;
  }
   */
svg:not(:root).svg-inline--fa,
svg:not(:host).svg-inline--fa {
  margin-left: -8px;
}





@media (max-width: 768px){


  .rowLink {
    display: inline;
   
    font-weight: 600;
  
    font-size: 12px;
    margin-bottom: 20px;
    margin-top: 10px;
}
.footer-left {
  margin-top: 6px;
  font-size: 12px;
  color: white;
  font-weight: 700;
}
.footerrd{
  border-radius: 2px ip !important;
}
.address {
  font-size: 12px;
  font-weight: 600;
}
.phone {
  /* margin: 0px; */
  /* padding: 1px; */
  color: black;
  font-size: 12px;
  font-weight: 600;
  display: contents;
}

.rowLink1{
  padding-left: 40px;
}
/* .rowlink2{
  text-align: left;
} */
.rowLink1 li{
  float: left;
  padding-right: 10px;
}
.rowLink2 li{
  float: left;
  padding-right: 10px;
}

.botm-links {
  display: flex;
  justify-content: center;
  /* padding: 5px 30px 0px 20px; */
}
}
@media (max-width:576px) {
  .footer-logo {

    padding: 18px;
  
}
  .f-main-image{
    height: 21px;
    width: 151px;
  }
  .arrorstyl {
    height: 14px;
    width: 20px;
    color: #fff;
}
}
@media (max-width: 320px) {
  .footer-left {
      margin-top: 6px;
      font-size: 10px;
      color: white;
      font-weight: 800;
  }
}

.for-location-icon-email{
  font-size: 20px !important;
  
  color: #247496;
}