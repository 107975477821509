.fontstyl {
  text-align: justify;
  font-size: 18px;
  padding-top: 10px;
  font-weight: 500;
}
ul{
padding-left: 20px;
padding-right: 10px;
}


@media  (max-width:480px) {
  .fontstyl {
    font-size: 12px;
  }
}