.form-container {
  width: 100%;
  max-width: 539px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 5px;
  background-color: #fff;
  box-sizing: border-box;
}

.regi-input {
  width: 100%;
  height: 57px;
  border-radius: 10px;
  border: 2px solid #247496;
  padding: 21px 50px;
  font-size: 18px;
  color: #070707;
  background-color: #fff;
}

.regi-input:focus {
  outline: none;
  border-color: #247496;
}

.input-container {
  position: relative;
  margin-bottom: 20px;
}

.input-container:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 55px;
  width: 46px;
  background-color: #247496;
  border-radius: 10px 0 0 10px;
}

.input-container img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  width: 20px;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.checkbox-input {
  margin-right: 10px;
}

.checkbox-label {
  font-size: 17px;
  font-weight: 400;
  margin-top: 10px;
}

.lgn-btn {
  text-align: center;
  margin-top: 5%;
}

.form-container button {
  background-color: #247496;
  color: white;
  padding : 6px 75px;
  /* width: 215px;
  height: 50px; */
  margin-top: 3px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 24px;
  font-weight: 500;
  margin-left: 6px;
}

.form-container button:hover {
  background-color: #247496;
}

.user_link {
  font-weight: 500;
  font-size: 18px;
  text-decoration: none !important;
  color: black;
}

.lgn-heading {
  color: #247496;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 30px;
}

.form-container hr {
  background-color: #247496;
  width: 37px;
  height: 2px;
  opacity: 1;
  margin: 3px 0;
  margin-bottom: 18px;
}

@media (max-width: 768px) {

  .form-container button {
    background-color: #247496;
    color: white;
    /* width: 150px;
    height: 35px; */
    padding: 6px 75px;
    margin-top: 3px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    margin-left: 6px;
}
  .lgn-heading {
    color: #070707;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 700;
}
.user_link {
  font-weight: 500;
  font-size: 14px;
  text-decoration: none !important;
  color: black;
}
.input-container:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 43px;
  width: 46px;
  background-color: #247496;
  border-radius: 10px 0 0 10px;
}
.regi-input {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border: 2px solid #247496;
  padding: 21px 50px;
  font-size: 12px;
  color: #070707;
  background-color: #fff;
}
  .form-container hr {
    display: none;
  }
}

@media (max-width: 425px) {
  .form-container {
    padding: 15px; 
  }
}

@media (max-width: 375px) {
  .form-container {
    padding: 15px; 
  }
}

@media (max-width: 320px) {
  .form-container {
    padding: 10px; /* Adjusted padding for smaller screens */
  }
}


.forgot-password{
  margin-top: -10px !important;
}

.userset{
  margin-top: 15px !important;
}

@media only screen and (max-width: 480px) and (min-width: 320px) {
  .userset{
    margin-top: 11px !important;
  }
}

.input-group .btn {
  position: relative;
  z-index: 0;
}