/* body{
  font-family: 'Ubuntu', sans-serif;
} */
.for-text-format {
  text-align: justify;
}

.first-para {
  padding-right: 33px;
  margin-top: 2%;
}

.second-para {
  padding-right: 33px;
  margin-top: 2%;
}

.custom-line {
  border-right: 1px solid rgba(4, 177, 239, 1);
}

.custom-img-parent {

  position: relative;
}

.img-absolute {
  position: absolute;
  bottom: 0px;
  display: flex;
  font-size: 20px;
  align-items: center;
  gap: 10px;
}

.custom-border-shadow {
  margin-top: 15px;
  border: none;
  box-shadow: 0px 0px 8.31px 1.66px #C2C2C2;
  /* box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 0px 10px 0 rgba(0, 0, 0, 0.4); */
  margin-bottom: 10px;
}

.first-head-details {
  font-size: 29px;
  font-weight: 700;
  padding-right: 30px;
}

.card-text-detail {
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
}

.Related_news_details {
  position: relative;
  display: inline-block;
  font-size: 29px;
  font-weight: 700;
  /* border-width: 3px; */
}

.para-detail {
  white-space: pre-line;
  display: inline;
  font-size: 18px;
  font-weight: 400;
  color: rgba(77, 77, 77, 1);
  line-height: 20px;
}

.Related_news_details::after {
  content: '';
  position: absolute;
  bottom: -2px;
  /* Adjust as needed to place the border correctly */
  left: 0;
  width: 34%;
  /* This makes the border span half the width of the element */
  border-bottom: 1px solid rgba(4, 177, 239, 1);
  /* Adjust the thickness and color as needed */
}

.second-head {

  font-size: 24px;
  font-weight: 700;
}

.fixed-width-slide {
  width: 230px !important;
}

.image-lineae-gradient-swiper {
  position: absolute;
  height: 45px;
  width: 100%;
  left: 0px;
  bottom: 0px;
  background: linear-gradient(0deg, #000000 -84.05%, rgba(0, 0, 0, 0) 94.59%);
}

.card-img-top-details {
  height: 195px;
  /* height: 215px; */
  border-radius: 5px 5px 0px 0px;
  object-fit: cover;
  width: 100%;
}

.card-body-detail {
  height: 90px;
}

.detailimg {
  height: 520px;
}

.detail-img-div {
  width: 100%;
  padding-right: 30px;
}

.para-detail p {
  display: inline;
}

.card-text.card-text-detail {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 3;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
}
@media (max-width: 768px) {
  .card-text.card-text-detail{
    height: 40px;
  }
}


@media (max-width:1200px) {
  .detail-Link {
    text-align: center;
    display: block;
  }

  iframe {
    height: 600px !important;
  }
}



@media (max-width:1024px) {

  iframe {
    height: 498px !important;
  }

  .detailpptimg {
    height: 287px !important;
  }

  .gradient-text {
    font-size: 16px !important;
  }

  .para-detail {
    font-size: 16px;
  }

  .card-img-top {

    height: 190px;
  }

  .second-head {
    font-size: 20px;
  }

  /* .leftImg-detail {
    height: 24px;
    width: 44px; 
} */
  .first-head-details {
    font-size: 24px;
  }

  .Related_news_details {
    margin-top: 10px;
    font-size: 24px;
  }

  .card-text-detail {
    font-size: 14px;
    height: 40px;
  }
}

@media (max-width:768px) {
  .detailimg {
    height: 300px !important;
  }

  .custom-line {
    border-right: 0px solid rgba(4, 177, 239, 1);
  }

  .detailimg {
    padding: 0% !important;
  }
  .parent-container{
    padding: 0% !important;
  }

  .detail-img-div {
    padding: 0% !important;
  }

  .first-para {
    padding-right: 0px;
    margin-top: 2%;
  }

  .second-para {
    padding-right: 0px;
    margin-top: 2%;
  }
}


@media (max-width: 576px) {
  .card-text.card-text-detail {
    font-size: 14px;
  }

  .card-text-detail {
    font-size: 14px;

    line-height: 20px;
  }

  .para-detail {
    display: block;
    font-size: 12px;
    font-weight: 400;
    color: rgba(77, 77, 77, 1);
    line-height: 16px;
  }

  .first-letter {
    font-size: 36px;
    line-height: 1;
    font-weight: 500;
    float: left;
    margin-right: 5px;
    padding: 0;
  }

  .Related_news_details {
    position: relative;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    margin-top: 20px;
    /* border-width: 3px; */
  }

  .detail-font {
    font-size: 12px !important;
    font-weight: 400 !important;
  }

  .detail-Link {
    text-align: center;
    display: block;
  }

  .detail-img-div {
    padding: 0% !important;
  }
  .parent-container{
    padding: 0% !important;
  }

  .custom-line {
    border: none;
  }

  .first-para {
    padding-right: 0px;
  }

  .second-para {
    padding-right: 0px;
  }

  .first-head-details {
    font-size: 16px;
    font-weight: 700;
    padding-right: 0px;
  }

  .second-head {
    font-size: 16px;
    font-weight: 600;
  }
}

.gradient-text {
  font-size: 20px;
  font-weight: 700;
  background: linear-gradient(90deg, #00BCFF 3.74%, #5162FF 75.4%);
  -webkit-background-clip: text;
  /* For Safari */
  -webkit-text-fill-color: transparent;
  /* For Safari */
  background-clip: text;
  /* Standard property */
  color: transparent;
  /* Fallback for other browsers */
  margin-left: 15px;
}

div#WACStatusBarContainer {
  display: none;
}

a#ChromelessStatusBar\.Options-Small14 {
  display: none;
}

.para-detail::first-letter {
  float: left;
  font-weight: 500;
  font-size: 34px;
  line-height: 30px;
  /* padding-right: 0.1em; */
  /* margin-right: 0.1em; */
}




.parent-container {
  display: flex;
  padding-right: 30px;
  justify-content: center !important;
  align-items: center !important;
  height: 100vh;
  /* or whatever height you need */
}

.pdf-container {
  /* width: 100% !important; */
  /* Adjust width as needed */
}

.src {
  object-fit: cover;
  height: 28px !important;
  width: 55px !important;
}

.date-text {
  font-size: 17px;
  font-weight: 500;
  color: #247496;
}
@media (max-width: 576px){
  .date-text{
    font-size: 16px !important;
  }
}
