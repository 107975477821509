.Form-field-parent {
  width: 100%;
  max-width: 539px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 5px;
  background-color: #fff;
  box-sizing: border-box;
}

.regi-input-signup {
  width: 100%;
  height: 57px;
  border-radius: 10px;
  border: 2px solid #247496;
  padding: 21px 50px;
  font-size: 18px;
  color: #070707;
  background-color: #fff;
}

.form-filed {
  position: relative;
  margin-bottom: 20px;
}

.form-filed input {
  font-weight: 400;
  font-size: 15px;
  /* color: #8c8c8c; */
  color: #070707!important;
}

.form-filed:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 55px;
  width: 46px;
  background-color: #247496;
  border-radius: 10px 0 0 10px;
}

.form-filed img {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  width: 20px;
}

hr {
  margin: 1rem 0;
  border: none;
  border-top: 2px solid #247496;
}

.sgnup-heading {
  color: #247496;
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 10px; /* Adjusted for spacing */
}

.Form-field-parent hr {
  background-color: #247496;
  width: 37px;
  height: 2px;
  opacity: 1;
  margin: 3px 0;
  margin-bottom: 18px;
}

.sgn-btn {
  text-align: center;
  margin-top: 5%;
}

.Form-field-parent button {
  background-color: #247496;
  color: white;
  padding: 7px 70px;
  /* width: 215px;
  height: 50px; */
  margin-top: 3px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 24px;
  font-weight: 500;
  margin-left: 6px;
}

input#agreeTerms {
  width: auto; /* Adjusted width for checkbox */
  margin-top: 12px;
  margin-right: 10px; /* Adjusted margin for spacing */
  vertical-align: middle;
}

input#agreeTerms {
  width: 21px;
  margin-top: 12px;
  margin-left: 22px;
  border-color: #247496;
  height: 21px;
}

.checkbox-label {
  font-size: 18px;
  font-weight: 400;
  color: #8c8c8c;
}

.Signup_link {
  font-weight: 500;
  font-size: 18px;
  text-decoration: none;
  color: black;
  display: block; /* Block level for better touch targets */
  margin-top: 10px; /* Adjusted margin for spacing */
}

.user1 {
  text-align: center;
  margin-top: 20px;
}

.tcp{
  text-decoration: none;
  color: #247496;
}
@media (max-width: 768px) {
  .sgnup-heading {
    color: #247496;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 10px;
}


  .regi-input-signup {
    padding: 21px 55px; /* Adjusted padding for smaller screens */
  }

  .sgnup-heading {
    color: #070707;
    margin-bottom: 20px;
  }

  .Form-field-parent hr {
    display: none; /* Hide the hr on smaller screens */
  }

  .Signup_link {
    margin-top: 15px; /* Adjusted margin for spacing */
  }

  .item {
    font-weight: 500;
    font-size: 15px;
    text-decoration: none !important;
    color: black;
  }
}

@media (max-width: 768px) {
  .item {
    font-weight: 500;
    font-size: 11px;
    text-decoration: none !important;
    color: black;
  }
}

input {
  outline: #247496;
}


@media only screen and (max-width: 480px) {
  .regi-input-signup {
    font-size: 11px !important;
  }
}

.eye-icon-container{
  position: absolute!important;
  right: 46px;
  z-index: 11;
  display: flex;
 
  top: 2rem; }

  .field-errorMessage{
    margin-top: -0.75rem;
  }