.custom-img-parent-News {
  position: relative;
}

.img-absolute-News {
  position: absolute;
  bottom: 0px; 
  display: flex;
  align-items: center;
  gap: 10px;
}

.custom-border-shadow-News {
  box-shadow: 0px 0px 10px 1px rgba(194, 194, 194, 1);
  border-radius: 10px;
  border: none;
}

.custom-nav-link-tab {
  background: #247496;
  text-align: center;
  padding: 21px 9px 9px 9px;
}

.custom-row-bg {
  background-color: #ECF9FF;
  height: 50%;
}

.sub-category {
  margin-top: 10px !important;
  background-color: #ECF9FF;
  text-align: center;
  margin-left: 3px;
}

.sub-category a.navlink {
  color: black !important;
  margin: 0;
}

.sub-category .navlink {
  padding: 10px;
}

.allbg-navlink {
  background-color: #247496;
  color: #fff;
  text-align: center;
  text-decoration: underline;
}

.card-cont1 {
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 0px 10px 0 rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  margin-bottom: 20px;
  position: relative;
}

.card-img img {
  width: 100%;
}

.navlink {
  padding: 10px 0px;
}

.card-text-style {
  position: absolute;
  bottom: 91px;
  color: #fff;
  display: flex;
  gap: 10px;
}

.for-hide-web {
  display: none;
}

.news.card-para p {
  padding: 10px 14px 10px 10px;
  font-weight: 500;
}

.botm-links {
  display: flex;
  justify-content: center;
}

.bott-aboutus {
  margin-top: 20px;
}

.allbg {
  background-color: #247496;
}

.allbg.navlink {
  color: white !important;
}

.allbg.navlink.nvlk a.nav-link {
  color: #ffff !important;
}

.image-lineae-gradient {
  position: absolute;
  height: 45px;
  width: 100%;
  left: 0px;
  bottom: 0px;
  background: linear-gradient(0deg, #000000 -84.05%, rgba(0, 0, 0, 0) 94.59%);
}

.bg-color {
  background-color: #247496;
  /* Example background color */
  color: #fff;
  /* Add this to change the text color */
}

.card-img-top-news {
  object-fit: cover;
  /* height: 215px; */
  height: 195px ;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
}

.nav-tabs {
  --bs-nav-tabs-border-color: none;
}

.tabs-news div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-image-news {
  position: relative;
}

.alltext {
  font-weight: 400;
  font-size: 18px;
  text-decoration: none;
  color: rgba(114, 114, 114, 1);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.alltext2 {
  text-decoration: none;
  color: #f6f1f1;
}
 
.dropdown-news {
  max-height: 200px;
  /* overflow: scroll; */
  position: absolute;
  z-index: 1;
  background: white;
  right: 0;
}

.dropdown-news>div {
  border-bottom: 1px solid rgba(203, 203, 203, 1);
  /* margin: 0px; */
  line-height: 15px;
  /* padding-left: 10px; */
}

.alltext-drop {
  padding: 0px 20px 0px 10px;
}

.alltext-drop :hover {
  text-decoration: none;
}

.sub-category>div {
  margin: 0px;
  padding-left: 0px;
  display: flex;
  height: 100%;
  justify-content: start;
}
.custom-text{
  color: #247496;
  font-weight: 500;
  font-size: 14px;
}
.custom-card-body-news {
  padding: 12px;
  /* height: 85px; */
}

.All-col {
  margin-top: 30px;
}

.mainHeadAbout {
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  margin: 20px;
}

.card_content-news {
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
}

.news-card-img {
  height: 19.3rem;
  border-radius: 11px;
  width: 100%;
}

.main_content_for_absoulte-news {
  width: 100%;
  border-radius: 0px 0px 10px 10px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 53px 0px 0px 10px;
  background: linear-gradient(0deg, #000000 -24.05%, rgba(0, 0, 0, 0) 94.59%);
}

.not-found-div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  font-size: 18px;
}

.leftImg {
  object-fit: cover;
  height: 28px;
  width: 55px;
}

a:hover {
  /* color: #0056b3; */
  text-decoration: none;
}

.card-text.for-custom-card-text-news.card_content-news {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 18px;
   height: 40px; 
  line-height: 20px;
}

/* Media Queries */

@media (max-width: 990px) {
  .alltext {
    font-size: 12px !important;
  }

  .news-card-img {
    height: 18.45rem;
  }

  .card_content-news {
    font-size: 14px;
    line-height: 18px;
  }
}

@media (max-width: 768px) {
  .not-found-div {
    font-size: 12px;
  }

  .sub-category {
    display: none;
  }

  .for-hide-web>div {
    padding: 0px !important;
  }

  .alltext {
    font-size: 12px !important;
  }

  .mainHeadAbout {
    display: none;
  }
}

@media (max-width: 576px) {

  .card-text.for-custom-card-text-news.card_content-news {
    font-size: 16px;
    height: 50px;
  }

  .top-image-text-news {
    font-size: 14px !important;
    line-height: 18px !important;
  }

  /* .allNewsbox>div:first-child {
    display: none;
  } */

  .news-card-img {
    border-radius: 10px !important;
  }

  .card_content-news {
    font-size: 14px;
    font-weight: 500;
    padding-top: 10px;
  }

  .card-img-top-news {
    object-fit: cover;
    height: 97px;
    width: 100px !important;
    border-radius: 5px;
  }

  .All-col {
    margin-top: 20px;
  }

  .image-lineae-gradient {
    display: none;
  }

  .leftImg {
    height: 15px;
    width: 30px;
  }

  .sub-category {
    display: none;
  }

  .hide-in-mobile {
    display: none;
  }

  /* .for-hide-web {
    display: inline;
    margin: 0px;
  } */

  .card-cont1 {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
  }

  .news .card-para {
    padding: 41px, 10px, 7px, 12px;
  }

  .card-text-style {
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    gap: 10px;
    padding-top: 3%;
    padding-left: 3%;
  }

  .card-img {
    width: 100%;
    padding: 12px;
  }

  .card-cont1 {
    margin-bottom: 0px !important;
  }

  .custom-img-parent-News {
    position: unset;
    padding: 16px;
  }

  .img-absolute-News {
    position: absolute;
    left: 17px;
    display: flex;
    align-items: center;
    gap: 6px;
    bottom: 93px;
}

  .custom-mobile-view-news {
    color: rgba(131, 131, 131, 1) !important;
    font-size: 12px;
    font-weight: 400;
  }

  .custom-border-shadow-News {
    display: flex;
    flex-direction: row-reverse;
    position: relative;
  }

  .custom-card-body-news {
    margin-top: 5%;
    width: 100%;
    padding: 16px 0px 16px 16px;
  }
  .custom-card-body-news-no {
    margin-top: 3%;
    padding: 8px 0px 16px 16px;
}

  .cusotm-row-bg {
    display: none;
  }
}

@media (max-width: 380px) {
  .top-image-text-news {
    font-size: 14px !important;
    line-height: 18px !important;
  }

  .card-img-top {
    height: 80px;
    width: 80px;
  }

  .cusotm-row-bg {
    display: none;
  }

  /* .for-custom-card-text-news {
    font-size: 14px !important;
  } */
  .custom-card-body-news {
    margin-top: 5%;
  }

  .custom-mobile-view-news {
    font-size: 10px;
    color: black !important;
  }
}

@media (max-width: 330px) {
  .top-image-text-news {
    font-size: 10px !important;
  }

  /* .for-custom-card-text-news {
    font-size: 10px !important;
  } */

}

/*----------------------- div- height------------------------- */

@media (max-width: 1400px) {
  .div-height{
    /* height: 150px !important; */

  }
  /* .news-card-image {
    height: 15.3rem !important;
    border-radius: 11px;
    width: 100%;
  } */
}


@media (max-width: 576px) {

  .div-height {
    /* height: 83px !important; */
}
}


/* 
.news-card-image {
  height: 19.3rem;
  border-radius: 11px;
  width: 100%;
} */