.custom-header{
    background-color: #247496;
    color: #fff;
}
.btn-save-custom{
    background-color: #247496 !important;
    border-color: #247496 !important;
}
.btn-close-custom, .btn-close-custom:hover{
    background-color: #fff !important;
    border-color: #247496 !important;
    color: #247496 !important;
}