/* body{
  font-family: 'Ubuntu', sans-serif;
} */
body.no-scroll {
  overflow: hidden;
}


#topbar {
    padding: 10px 0px 10px 0px;
    border-bottom: 1px solid #04B1EF;
  }
  
  #sidevar {
    display: none;
  }
  
  #topbar .topbtn1 {
    /* width: 71px; */
    /* height: 21px; */
    margin-right: 10px;
    padding: 0px 12px;
    /* background-color: #247496; */
    border: none;
    color: #247496;
    font-size: 14px;
    background-color: white;
    cursor: pointer;
    border-radius: 7px;
    border: 1px solid #247496;
  }
  #topbar .topbtn1bg{
    background-color: #247496;
    margin-right: 10px;
    border: none;
    padding: 0px 12px;
    font-size: 14px;
    border: 1px solid #247496;
    cursor: pointer;
    border-radius: 7px;
    /* width: 71px; */
    color: white;
  }
  #topbar .topbtn2 {
    /* width: 71px; */
    /* height: 21px; */
    margin-right: 10px;
    padding: 0px 12px;
    background-color: white;
    border: none;
    color: #247496;
    font-size: 14px;
    cursor: pointer;
    border-radius: 7px;
    border: 1px solid #247496;
  }
  
  #topbar img {
    height: 21px;
    width: 21px;
  }

  #topbar a{
    margin-right: 6px;
  }
  
  #logo {
    display: flex;
    justify-content: center;
    padding: 20px 10px 20px 10px;
    align-items: center;
}
  
  #logo div {
    display: flex;
    justify-content: center;
  }
  
  
  #nav {
    background-color: #247496;
    width: 100%;
    height: 60px;
    /* padding: 12px; */
    margin: 0px;
}
  
  #nav a {
    color: white;
    text-decoration: none;
    font-size: 18px;
    font-weight: 400;
    /* font-family: 'Ubuntu', sans-serif; */
}
  .navul{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0px;
}
.cardRow {
  display: flex;
  justify-content: space-between;
}
  .collcard {
    width: 100%;
    position: absolute;
    /* z-index: 2; */
    z-index: 9999;
    display: flex;
    justify-content: space-around;
    box-shadow: 1px 1px 5px 0px #D8D8D8, -1px 0px 5px 0px #00000040;
    left: 0;
    right: 0;
    padding: 25px;
  }
  
  .cardinner {
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
    /* font-family: 'Ubuntu', sans-serif; */
    text-align: justify;
}
  
.cardinner ul {
  font-size: 16px;
  list-style-type: none;
  font-weight: 400;
  padding: 0;
  color: rgba(125, 125, 125, 1);
}
.allulcard {
  margin-top: 28px;

}
  .cardinner ul li {
    border-bottom: 2px dotted rgba(125, 125, 125, 1);
    padding: 6px 0;
  }
  .ultext {
    color: rgba(125, 125, 125, 1);
    margin-left: 15px;
    /* border-bottom: 2px dotted black; */
    padding: 0px 0px 8px 0px;
  
}

.opencard{
  height: 60px;
  padding: 10px 0px 0px 0px;
  background-color: white;
  /* color: crimson; */
}
.opencard a{
  color: black !important;
}

  
.glyphicon-chevron-right {
  font-size: 18px;
  font-weight: 500;
  color: rgba(36, 116, 150, 1);
  float: left;
}
.card-heading{
  height: 50px;
  display: flex;
  margin-left: 18px;
  font-size: 18px;
  font-weight: 500;
  color: rgba(36, 116, 150, 1);
  margin-bottom: 10px;
}
.card-link{
  text-decoration: none;
  color: rgba(36, 116, 150, 1);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.allulcard {
  margin: 0;
  padding: 0;
  list-style: none;
}

.allulcard li {
  margin-bottom: 10px; /* Ensure consistent spacing between list items */
}
.allulcard a {
  text-decoration: none;
  color: rgba(125, 125, 125, 1);
}
  
  #searchBar {
    justify-content: center;
    z-index: 2;
    position: absolute;
    margin-top: 20px;
}
  .searchBardiv{
    display: flex;
    justify-content: center;
  }
  
  #searchBar input {
    width: 560px;
    height: 40px;
    padding: 5px;
    font-size: 16px;
    border-radius: 5px;
    margin-right: 5px;
    border: 2px solid #04B1EF;
}
  
#searchBar button {
  background-color: #04B1EF;
  color: white;
  border: none;
  cursor: pointer;
  height: 40px;
  font-size: 20px;
  border-radius: 5px;
  width: 144px;
}
  /* .css-eqpfi5-MuiAccordionSummary-content{
    margin: 0px 0 !important;
    height: 36px;
  } */

  .blur-background{
    /* filter: blur(2px);
    background-color: rgba(255, 255, 255, 0.6); */
    /* background-color: rgba(0, 0, 0, 0.7); */

    position: relative; /* Ensure the overlay covers the entire element */

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
       background: rgba(255, 255, 255, 0.6); 
      z-index: 1;
  }
  } 
  .active {
    background-color: #ECF9FF  !important;
    ;
  }
  .Home-icon{
    height: 28px;
    width: 28px;
  }
  .all-hed-icon{
    font-size: 22px;
  }

  .li-text-header {
    border-bottom: 1px solid grey;
    text-align: start;
    list-style: none;
    padding: 10px;
}
.li-active-header{
  border-bottom:2px solid ;
}
.allulcard li {
  text-align: left;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
  @media (max-width:980px){
    .all-hed-icon {
      font-size: 18px;
  }
    .Home-icon {
      height: 24px;
      width: 24px;
  }
  }
  @media only screen and  (max-width: 1024px){
    .allulcard {
      margin-top: 21px;
  }

    .glyphicon-chevron-right {
      font-size: 14px;
  }

    #nav a {
      font-size: 16px;
   
  }
    .cardinner {
      /* font-size: 23px; */
      /* font-family: 'Ubuntu', sans-serif; */
      text-align: justify;
      font-size: 10px;
  }
  .cardinner ul {
    font-size: 8px;
    list-style-type: none;
    padding: 0;
}

  }

  /* @media only screen and  (max-width: 2560px) {
    #nav a {
      font-size: 18px;
      margin: 0px 48px 0px 0px;
  }
   
  }

  @media only screen and (max-width: 1200px) {
    #nav a {
      font-size: 16px;
      margin: 0px 18px 0px 0px;
  }

 
}
@media only screen and (max-width: 990px) {
     #nav a {
        font-size: 13px;
        margin: 0px 21px 0px 0px;
    }

 
} */

  
  @media only screen and  (max-width: 768px) {
      .mobile-card-container {
          position: absolute;
          right: 12px;
          z-index: 1;
          overflow: scroll;
          top: 40px;
          max-width: 150px;
          max-height: 314px;
      }
    #topbar {
      position: absolute;
      top: -2;
      border: hidden;
    }
    /* #topbar div{
      display: none;
    }   */
    /* #topbar {
      display: none !important;
    } */
  
   
    
    #sidevar {
      display: flex;
      text-align: center;
      justify-content: space-between;
      align-items: center;
      position: relative;
  }
    #sidevar a{
      color: black;
    }
  
    #logo {
      margin: 0px 0px 0px 0px;
      border-bottom: 0.3px solid #04B1EF;
    }
  
    #logo img {
      height: 21px;
      width: 151px;
    }
  
    #nav {
      display: none;
    }

    .fontli {
      font-weight: 700 !important;
  }
  
  .pagehead {
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
}
.pagehead_after {
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  margin-left: 20px;
}
    #openbar {
      width: 90%;
      height: 100%;
      background-color: white;
      position: absolute;
      top: 0px;
      left: 0px;
      /* z-index: 2; */
      z-index: 9999;
      overflow: scroll;
    }
    #openbar ul {
      position: absolute;
      left: 0px;
      padding: 0px;
      width: 100%;
      margin: 0px;
  }
    
    .underul li a{
      margin-left: 20px;
    }
    #openbar a {
      color: black;
    }
  
    .btn2{
      background-color: white;
      width: 71px;
      height: 25px;
      margin-right: 10px;
      padding: 0;
      border: none;
      color: #247496;
      font-size: 13px;
      cursor: pointer;
      border-radius: 7px;
      border-color: #247496;
      border: 2px solid #247496;
    }
    .btn1 {
      width: 71px;
      height: 25px;
      margin-right: 10px;
      padding: 0;
      background-color: #247496;
      border: none;
      color: white;
      font-size: 13px;
      cursor: pointer;
      border-radius: 7px;
      margin-left: 20px;
  }
    
  
    #social a {
      margin-right: 10px;
    }

    #mobbtn {
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  
    #searchdivmob {
      display: flex;
      justify-content: center;
      margin-top: 5px;
    }
    #mobSearchBar{
      position: absolute;
      z-index: 2;
    }
    #mobSearchBar input {
      height: 30px;
      padding: 10px;
      width: 180px;
      font-size: 12px;
      border-radius: 5px;
      margin-right: 6px;
      /* border-color: #04B1EF; */
      border-top-color: #04B1EF;
      border: 2px solid #04B1EF;
  }
  
  #mobSearchBar button {
    padding: 5px 10px;
    background-color: #04B1EF;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 12px;
    border-radius: 5px;
    height: 30px;
}
      #accordian{
        font-size: 2px;
        width: 250px;
      }

      .lifirst-item {
        border-top: 0.5px solid grey;
        margin-top: -8px;
        padding-top: 15px;
        padding-left: 15px;
    }
    .liall-item {
      padding-top: 15px;
      padding-left: 15px;
  }

    .ullitext{
      font-size: 14px;
    }
    .displayNone{
      display: none
    }

      
  }
@media screen and (max-width:576px) {
  .pagehead_after {
    font-size: 18px;
}
}

  @media only screen and  (max-width: 320px){
    #pagehead {
      display: inline-block;
      font-size: 135%;
      padding: 4px 0px 0px 0px;
  }

  
  #mobSearchBar input {
    height: 30px;
    padding: 3px;
    font-size: 15px;
    border-radius: 5px;
    margin-right: 6px;
    border-color: #04B1EF;
    border-top-color: #04B1EF;
    width: 115px;
}

#mobSearchBar button {
  padding: 5px 10px;
  background-color: #04B1EF;
  color: white;
  width: 57px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;
}


  }


  .b-color{
    position: absolute;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
    top: 0;
}

 @media only screen and (max-width: 768px) {
    .mob-hidden {
        display: none !important;
    }
} 