.card-box-video{
    height: 200px;
}

.card-img-top-video{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px 10px 0px 0px;
}

video{
  width: 100% !important;
  object-fit: cover;
  height: 100% !important; 
  border-radius: 10px 10px 0px 0px;
}
@media (max-width: 576px) {
  .custom-border-shadow-News{
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 10px 1px #c2c2c2;
  }
  .card-img-top-video{
    height: 97px !important;
    width: 100px !important;
    border-radius: 5px !important;
    object-fit: cover;
  }
  video{
    height: 97px !important;
    width: 100% !important;
    border-radius: 5px;
    object-fit: cover;
  }
    .custom-card-body-video {
      margin-top: 5%;
      padding: 16px 0px 16px 16px;
    }
    .custom-img-parent-video {
        position: static;
        padding: 16px;
    }
    .img-absolutes-News {
      position: absolute;
        bottom: 93px;
        display: flex;
        align-items: center;
        gap: 6px;
        left: 17px;
  }
    
        .img-absolute-video {
            position: absolute;
            bottom: 93px;
            display: flex;
            align-items: center;
            gap: 6px;
            left: 17px;
        }
        .card-box-video {
            height: auto;
        }
        .image-lineae-gradient-video{
            display: none;
        }
        .card-box-video {
           height: auto !important; 
      }
     
    
}
.image-lineae-gradient-vdo {
  position: absolute;
  height: 45px;
  width: 100%;
  left: 0px;
  bottom: 0px;
  /* background: linear-gradient(0deg, #000000 -84.05%, rgba(0, 0, 0, 0) 94.59%); */
}
.card-box-video {
     height: 200px;  
  }
  
  .card-img-top-video {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
  }
  
  /* @media (max-width: 576px) {
    .custom-card-body-video {
      margin-top: 0%;
      padding: 12px;
    }
    .custom-img-parent-video {
      position: relative;
      padding: 0px;
    }
    .img-absolute-video {
      position: absolute;
      bottom: 0px;
      display: flex;
      align-items: center;
      gap: 10px;
      left: 0px;
    }
  }

  @media (max-width: 576px) {
    .h-video{
        height: 200px !important;
    }
  } */