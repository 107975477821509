
.otp-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}


.resend-otp {
  cursor: pointer;
  color: #247496;
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid #247496;
  transition: all 0.3s ease; 
}

.resend-otp:hover {
  background-color: #247496;
  color: white;
  border-color: #247496;
}
